<template>
	<!--서비스 상세 페이지 - 데이터-->
	<div class="Cycontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2 v-scrollanimation class="header_h2" style="display: none">
						데이터 암호화 취약점 분석 및 컨설팅 서비스
					</h2>
					<h1 class="servicedata_title">
						데이터 암호화 취약점 분석 및 컨설팅 서비스
					</h1>
					<p>
						데이터 암호화는 기업의 중요한 정보 보호를 위한 필수 요소입니다.<br />
						그러나 잘못된 암호화 구현이나 관리 소홀로 인해 심각한 데이터 유출 및
						해킹의 위험이 존재합니다.<br />
						본 서비스는 귀사의 데이터 암호화 체계를 점검하고,<br />
						취약점을 분석하여 최적의 보안 솔루션을 제안합니다.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<router-link to="/serviceApplication">
							<button class="btn--md btn--fill-gray">신청하기</button>
						</router-link>
					</div>
				</div>
			</div>
			<!-- content1 end -->

			<!--content4-->
			<div id="content4_data">
				<div class="content4_box_data">
					<div class="content_box">
						<div class="img_box1"><img :src="ServicedataVulnerability" /></div>
						<div class="text_box">
							<h2>취약점 분석</h2>
							<!-- <h3>다양한 템플릿을 활용해서 손쉽게 제작</h3> -->
							<div class="border"></div>
							<ul>
								<li>데이터 암호화 방식 및 키 관리 체계 점검</li>
								<li>기존 보안 시스템 및 정책의 취약점 평가</li>
								<li>데이터 보호 수준 및 위험 요소 분석</li>
							</ul>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>맞춤형 컨설팅</h2>
							<!-- <h3>선택한 데이터를 여러 종류의 차트로 자동 생성</h3> -->
							<div class="border"></div>
							<ul>
								<li>최신 암호화 기술 및 표준에 대한 정보 제공</li>
								<li>고객 맞춤형 보안 정책 및 절차 수립 지원</li>
								<li>암호화 솔루션 구현 및 테스트</li>
							</ul>
						</div>
						<div class="img_box2"><img :src="ServicedataCustomized" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="ServicedataReport" /></div>

						<div class="text_box">
							<h2>보고서 제공</h2>
							<!-- <h3>Mining에서 간편하게 수치를 입력하고 저장</h3> -->
							<div class="border"></div>

							<ul>
								<li>취약점 분석 결과 및 권장 사항을 포함한 상세 보고서 제공</li>
								<li>프로젝트 진행 후 후속 점검 및 지원</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!--content4 end-->

			<!--content5 end-->
			<!--컨텐츠 6-->
			<div id="BIcontent6_data">
				<div class="BIBorder servicecontent__wrapper">
					<div class="blueBorder">
						<div class="serviceBI__textbox">
							<div class="H1Name">
								데이터 암호화 취약점 분석 및 컨설팅 서비스
							</div>

							<div class="GOBI">
								<router-link to="/serviceApplication">
									<button class="GoToBI">서비스 신청 바로가기</button>
								</router-link>
								<router-link to="/inquiry">
									<button class="GoToAsk">소개자료 다운로드</button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- en -->
	<div class="Cycontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2 v-scrollanimation class="header_h2" style="display: none">
						Data Encryption Vulnerability Analysis and Consulting Service
					</h2>
					<h1 class="servicedata_title">
						Data Encryption Vulnerability Analysis and Consulting Service
					</h1>
					<p>
						Data encryption is an essential component for protecting critical
						business information. <br />
						However, improper encryption implementation or lack of management
						can lead to severe risks of <br />
						data breaches and hacking. <br />
						Our service inspects your company’s data encryption framework,
						analyzes vulnerabilities,<br />
						and recommends optimal security solutions.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<router-link to="/serviceApplication">
							<button class="btn--md btn--fill-gray">Apply</button>
						</router-link>
					</div>
				</div>
				<!-- service crypto main benner end-->
			</div>
			<!-- content1 end -->

			<!--content4-->
			<div id="content4_data">
				<div class="content4_box_data">
					<div class="content_box">
						<div class="img_box1"><img :src="ServicedataVulnerability" /></div>
						<div class="text_box">
							<h2>Vulnerability Analysis</h2>
							<!-- <h3>다양한 템플릿을 활용해서 손쉽게 제작</h3> -->
							<div class="border"></div>

							<ul>
								<li>
									Inspection of data encryption methods and <br />
									key management framework
								</li>
								<li>
									Evaluation of vulnerabilities in existing security systems
									<br />
									and policies
								</li>
								<li>Analysis of data protection levels and risk factors</li>
							</ul>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>Customized Consulting</h2>
							<!-- <h3>선택한 데이터를 여러 종류의 차트로 자동 생성</h3> -->
							<div class="border"></div>

							<ul>
								<li>
									Providing information on the latest encryption <br />
									technologies and standards
								</li>
								<li>
									Supporting the development of customized security <br />
									policies and procedures
								</li>
								<li>Implementation and testing of encryption solutions</li>
							</ul>
						</div>
						<div class="img_box2"><img :src="ServicedataCustomized" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="ServicedataReport" /></div>

						<div class="text_box">
							<h2>Report Provision</h2>
							<!-- <h3>Mining에서 간편하게 수치를 입력하고 저장</h3> -->
							<div class="border"></div>

							<ul>
								<li>
									Providing a detailed report including vulnerability <br />
									analysis results and recommendations
								</li>
								<li>
									Follow-up assessments and support after project <br />
									completion
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!--content4 end-->

			<!--컨텐츠 6-->
			<!--컨텐츠 6-->
			<div id="BIcontent6_data">
				<div class="BIBorder servicecontent__wrapper">
					<div class="blueBorder">
						<div class="serviceBI__textbox">
							<div class="H1Name">
								Data Encryption Vulnerability Analysis and Consulting Service
							</div>

							<div class="GOBI">
								<router-link to="/serviceApplication">
									<button class="GoToBI">Go to Service Application</button>
								</router-link>
								<router-link to="/inquiry">
									<button class="GoToAsk">
										Download Introduction Materials
									</button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--content6 end-->
		</div>
	</div>
</template>

<script>
import ServicedataVulnerability from '../assets/images/service/servicedataVulnerability Analysis.png';
import ServicedataCustomized from '../assets/images/service/servicedataCustomized Consulting.png';
import ServicedataReport from '../assets/images/service/servicedataReport Provision.png';

export default {
	data() {
		return {
			service: 1,
			ServicedataVulnerability,
			ServicedataCustomized,
			ServicedataReport,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
