<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<ServiceContentData></ServiceContentData>
		<Footer></Footer>
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import ServiceContentData from '../components/ServiceContentDataEncryptionVulnerability.vue';

export default {
	components: {
		MainHeader,
		Footer,
		ServiceContentData,
		BreadCrumbs,
		MenuIntro,
	},
	data() {
		return {
			//
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko'
				? '데이터 암호화 취약점 분석 및 컨설팅 서비스'
				: 'Data Encryption Vulnerability Analysis and Consulting Service';
		},
		menuEn() {
			return this.computedValue === 'en'
				? ''
				: 'Data Encryption Vulnerability Analysis and Consulting Service';
		},
		crumbs() {
			return this.computedValue === 'ko'
				? [
						{ name: 'Home', link: '/' },
						{
							name: '서비스',
							link: '/service',
						},
						{
							name: '데이터 암호화 취약점 분석 및 컨설팅 서비스',
							link: '/serviceData',
						},
				  ]
				: [
						{ name: 'Home', link: '/' },
						{
							name: 'Service',
							link: '/service',
						},
						{
							name: 'Data Encryption Vulnerability Analysis and Consulting Service',
							link: '/serviceData',
						},
				  ];
		},
	},
	methods: {
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
